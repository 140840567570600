import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { View, Text, Platform, ScrollView } from 'react-native';

import rootStore from '../root.store.js';

import { SERVER_DOMAIN } from '../constants';

import { api, apiListener, apiCall as socketAPICall, getConnectionID } from '../core/socket.api';
import { cloneModel } from '../root.store';
import { styles } from '../main.styles';

import CheckBox from '../components/checkBox.component';

import TextInput from '../components/textInput.component';
import Dropdown from '../components/mobileUI/dropdown.component';
import Loader from '../components/loader.component';
import RadioButtons from '../components/radioButtons.component';
import Slider from '../components/slider.component';
import ColorPicker from '../components/colorPicker.component';
import ImagePicker from '../components/imagePicker.component';
import Button from '../components/button.component';
import LinkButton from '../components/linkButton.component';

import sha256 from '../core/sha256.api';

const ucFirst = (name) => typeof name === 'string' ? name.charAt(0).toUpperCase() + name.substring(1) : name;
const _camelCaseToLabel = (camelCaseText) => {
	if (typeof camelCaseText !== 'string') return camelCaseText;
	let label = camelCaseText.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
	return label.charAt(0).toUpperCase() + label.slice(1);
}

export class APIComponent extends React.Component {
	extraState = {}
	constructor(props) {
		super(props);
		this.state = { ...this.state, error: null, internalError: null, confirmModal: null, pagination: {currentPage: 1, listCount: 0}, layout: {width: 0, height: 0, left: 0, top: 0}, ...(this.extraState || {}) };
		this.subscriptions = [];
	}
	componentDidMount() {
		
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error: error.toString(), internalError: error.toString() };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
		this.apiCall('logError', {logType: 'REACT-NATIVE', logText: JSON.stringify({error, errorInfo})});
		setTimeout(() => {
			if (this.state.error) {
				window.onbeforeunload = () => {}
				location.reload();
			} else {
				this.setState({internalError: null})
			}
		}, 2000);
	}
	componentWillUnmount() {
		for (const subscription of this.subscriptions) subscription.unsubscribe();
		this.subscriptions = [];
	}
	componentDidUpdate(prevProps, prevState) {
	}
	/*
		in parent:
		renderContent() {    
			const childCall = this.createChildCall();
			return (<>
				<Child childCall={childCall} />
				<Button title="Call" onPress={() => childCall.call()} />
			</>);
		}

		in child:
		constructor (props) {
			super(props);
			if (props.childCall) props.childCall.fn = () => {
				console.log(this.state);
			}
		}
	*/
	createChildCall() {
		const childCall = {
			call: (...args) => {
				if (childCall.fn) childCall.fn(...args);
			},
			fn: null
		};
		return childCall;
	}
	camelCaseToLabel(camelCaseText) {
		return _camelCaseToLabel(camelCaseText);
	}
	ucFirst(word) {
		if (!word) return '';
		return ucFirst(word);
	}
	hashPassword(password) {
		return sha256(password).catch(console.error);
	}
	apiListener(path, callback) {
		const unsubscribe = apiListener({path, callback});
		this.subscriptions.push({unsubscribe});
		return unsubscribe;
	}
	apiCall(model, method, data) { // (model, method, data) or (method, data)
		let simpleCall = !data;
		if (!data) { data = method; method = model; }

		const setError = (err) => {
			this.updateState({ error: err });
			return Promise.reject(err);
		}

		if (simpleCall) return socketAPICall({ path: method, requestData: data });

		if (!(model in api) || !(method in api[model])) return setError('Model ' + model + ' api method "' + method + '" not found');
		return api[model][method](data).then((response) => {
			return response;
		}).catch((err) => {
			return setError(err);
		});
	}
	loadModelList(modelName, data = {}) {
		return api[modelName]?.['list' + modelName + 's']?.({data}).catch((error) => {
			console.error(error);
		});
	}
	renderError(errText = null) {
		if (errText) return (<Text style={styles.error}>{errText}</Text>);
		if (this.state.internalError) return (<Text style={styles.error}>{this.state.internalError}</Text>);
		if (this.state.error) return (<Text style={styles.error}>{this.state.error}</Text>);
		return null;
	}
	getImageUri(fileName) {
		return this.getFileUri(fileName);
	}
	getFileUri(fileName) {
		if (!fileName) return null;
		return SERVER_DOMAIN + '/customerWebPages/' + fileName;
	}
	renderLoader({size, style, text}) {
		return (
			<View style={[{ flex: 1, justifyContent: "center", alignItems: "center" }, style]}>
				<Loader size={size || "large"} text={text} />
				{this.renderError()}
			</View>
		);
	}
	loadingView() {
		return this.renderLoader({style: this.props.style, size: this.props.loadingSize, text: 'Loading ' + this.props.loadingName + '...'});
	}
	updateState(state, onDone) {
		this.setState({ ...this.state, ...state }, onDone);
	}
	renderSelectableTextField(label, value, multiline = false) {
		if (!label) label = ucFirst(name);
		return (<View style={{marginBottom: 10}}>
			<TextInput label={label}
				placeholder={label}
				onChangeText={() => {}}
				value={value}
				multiline={multiline}
				innerStyle={{height: 50}}
				autoFocus={false} />
		</View>)
	}
	renderTextField(label, value, multiline = false) {
		if (!label) label = ucFirst(name);
		return (<View style={{marginBottom: 10}}>
			<Text style={styles.label}>{label}:</Text>
			<Text>{value}</Text>
		</View>)
	}
	renderCheckBox(opt) { //opt = { label, onChange, value, onValue = true, offValue = false, style }
		return (<CheckBox {...opt} />);
	}
	renderRadioButtons({ label, options, value, onChange, style }) {
		return (<View style={style}>
			{label && <Text style={styles.label}>{label}:</Text>}
			<RadioButtons
				value={value}
				options={options}
				onChange={(value) => {
					onChange(value);
				}}
			/>
		</View>);
	}
	renderSlider({ label, min, max, value, valueSuffix, valuePreffix, color, onChange, style, step }) {
		return (<>
			<View style={{ flex: 1 }}>
				<Slider
					label={label}
					minimumValue={min}
					maximumValue={max}
					valueSuffix={valueSuffix}
					valuePreffix={valuePreffix}
					initialValue={value}
					color={color}
					onValueChange={onChange}
					style={style}
					step={step}
				/>
			</View>
		</>);
	}
	renderFilePicker(label, value, onChange, extensions) {
		return (<>
			<ImagePicker label={label} style={{marginVertical: 2}} uri={value} onChange={onChange} context={this} extensions={extensions} />
		</>)
	}
	renderColorPicker(label, value, onChange, palette, onPaletteAdd) {
		return (<>
			<ColorPicker label={label} style={{marginVertical: 2}} color={value} palette={palette} onPaletteAdd={onPaletteAdd} onChange={(newValue) => onChange(newValue)} />
		</>);
	}
	renderTextInput = ({ name, value, onUpdate, multiline, inputStyle, style, numeric, isPassword, placeholder }) => {
		const strValue = (value && typeof (value) != 'string') ? value.toString() : value; // convert non strings to string
		const innerStyle = { ...inputStyle };
		if (multiline && !innerStyle.height) innerStyle.height = 80;
		return (
			<View style={[styles.formGroupStacked, { width: '100%' }]}>
				{isPassword
					? <TextInput
						label={name}
						placeholder={placeholder || name}
						onChangeText={onUpdate}
						value={strValue}
						multiline={multiline == true}
						inputStyle={innerStyle}
						numeric={numeric}
						style={style}
						textContentType="password"
						autoCapitalize="none"
						secureTextEntry={true}
					/>
					: <TextInput
						label={name}
						placeholder={placeholder || name}
						onChangeText={onUpdate}
						value={strValue}
						multiline={multiline == true}
						inputStyle={innerStyle}
						numeric={numeric}
						style={style}
					/>
				}
			</View>
		);
	}
	renderDropdown = ({ label, name, defaultValue, options, onChange, numericInt = false, containerStyle = {}, smallStyle=false, numericFloat = false }) => {
		if (!name) name = label;
		const list = options?.map(option => {
			const label = (typeof option.label === 'string') ? option.label : option.label.toString();
			return (
				{ label: label, value: option.value }
			);
		}) || [];
		return (
			<View style={[styles.formGroupStacked, { width: '100%' }]}>
				<Dropdown
					displayText={name}
					selectedValue={defaultValue || ''}
					enabled={true}
					smallStyle={smallStyle}
					containerStyle={containerStyle}
					onValueChange={(itemValue, idx)=> {
						if (numericInt) itemValue = parseInt(itemValue);
						if (numericFloat) itemValue = parseFloat(itemValue);
						onChange(itemValue, idx);
					}}
					list={list}
				/>
			</View>
		);
	}
	showConfirmDialogue(title, content) {
		return new Promise((resolve, reject) => {
			this.updateState({
				confirmModal: {
					open: true, 
					title, 
					content, 
					closeModal: (accepted) => {
						this.updateState({ confirmModal: null });
						accepted ? resolve() : reject();
					}
				}
			});
		});
	}
	renderConfirmModal() {
		const confirmModal = this.state.confirmModal; // this.setState({confirmModal: {open: true, title: "", content: "", closeModal: (accepted) => {}}});
		if (confirmModal && confirmModal.open !== false) return (<Modal open={confirmModal.open !== false} showButtons={false} onClose={confirmModal.closeModal} style={{minHeight: 200, flexShrink: 1}}>
			<View style={{ flex: 1, flexDirection: 'column', minWidth: 450 }}>
				<Text style={styles.title}>{confirmModal.title}</Text>
				<ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1, padding: 20}} scrollIndicatorInsets={{ right: 1 }}>
					<View style={{ flex: 1, flexDirection: 'column' }}>
						{typeof confirmModal.content === 'string' ? (<Text>{confirmModal.content}</Text>) : confirmModal.content}
					</View>
				</ScrollView>
				<View>{this.renderError()}</View>
				<View style={{ flexDirection: 'row', marginTop: 20 }}>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<LinkButton title="Cancel" color="#A00" style={{ width: 100 }} onPress={() => confirmModal.closeModal(false)} />
					</View>
					<View style={{flex: 1, flexDirection: 'row-reverse'}}>
						<Button title="Continue" style={{width: 150}} onPress={() => confirmModal.closeModal(true)} />
					</View>
				</View>
			</View>
		</Modal>);
		return null;
	}
	render() {
		if (this.state.internalError) return this.renderError();
		if (!this.props.useLayout) return (this.renderContent) ? <>{this.renderConfirmModal()}{this.renderContent()}</> : null;
		
		return (
			<View style={[{flex: 1}, this.props.renderStyle]} onLayout={(layoutEvent) => {
				//console.log(layoutEvent.nativeEvent);
				const {width, height, left, top} = layoutEvent.nativeEvent.layout;
				this.setState({layout: {width, height, left, top}});
			}}>
				{this.renderConfirmModal()}
				{(this.renderContent) ? this.renderContent() : null}
			</View>
		);
	}
}

export class ModelComponent extends APIComponent {
	constructor(props) {
		super(props);
		this.state = { ...this.state, modelsLoading: false };

		this.userData = this.props.modelOptions?.root.userData;
		this.accountAccess = this.userData?.accountAccess;
		this.accountType = this.userData?.accountType;
	}
	componentDidMount() {
		super.componentDidMount();

		if (this.props.modelLoaded) {
			if (this.props.onModelLoaded) this.props.onModelLoaded();
			return;
		}

		// load the models
		this.loadModels(true);
	}
	deleteModel({model, id, children = []}) { // children = [{model, id, children = []}, ...]
		const apiMethod = 'delete'+model;
		const setError = (err) => {
			this.updateState({ error: err });
			console.log("Model " + model + " error: " + err);
		}
		if (!(model in api)) return setError('Model ' + model + ' does not exist in call to '+apiMethod);
		if (!(apiMethod in api[model])) return setError('Model ' + model + ' api method "' + apiMethod + '" not found');

		const data = { data: { id } };

		return api[model][apiMethod](data).then((response) => {
			console.log('deleted', model, id, response);
			const promises = [];
			if (children) for (const child of children) {
				promises.push(this.deleteModel(child));
			}
			return Promise.all(promises);
		}).catch((error) => {
			console.error(apiMethod, id, error);
		});
	}


	// {model = null, name = model, key, keyField = 'id', list = false, useKey, childrenOnly = false, loadChildren = (childrenOnly || false), require = true}

	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);

		let keysChanged = false;

		const props = this.props;
		const modelsArray = [...(props.modelOptions.models || [])];
		for (let i = 0; i < modelsArray.length; i++) {
			const modelOptions = modelsArray[i];

			const keyField = modelOptions.keyField || 'id';

			const key = (() => {
				const keyFn = modelOptions.key;
				if (!keyFn) return props[keyField];
				else if (typeof keyFn === 'function') return keyFn(props.modelOptions.root, props);

				return keyFn;
			})();
			const prevKey = (() => {
				const keyFn = modelOptions.key;
				if (!keyFn) return prevProps[keyField];
				else if (typeof keyFn === 'function') return keyFn(prevProps.modelOptions.root, prevProps);
				return keyFn;
			})();

			if (key != prevKey) {
				keysChanged = true;
				break;
			}
		}

		if (this.state.pagination?.currentPage != prevState.pagination?.currentPage) {
			keysChanged = true;
		}

		if (keysChanged) this.loadModels(true).then(()=> {
			if (props.onModelLoaded && props.modelLoaded === true) this.props.onModelLoaded(); // && prevProps.modelLoaded === false && props.modelLoaded === true) this.props.onModelLoaded();
		});
		return keysChanged;
	}
	loadModels(forceLoad = false) {
		const props = this.props;

		const modelsArray = [...(props.modelOptions.models || [])];
		
		const promises = [];

		for (let i = 0; i < modelsArray.length; i++) {
			const modelOptions = modelsArray[i];
			promises.push(
				this.loadModel(modelOptions, forceLoad)
			);
		}
		return Promise.all(promises);
	}
	loadModel(model, forceLoad = false) {
		const props = this.props;

		const modelName = model.model;
		const keyField = model.keyField || 'id';
		const key = (() => {
			const keyFn = model.key;
			if (!keyFn) return props[keyField];
			else if (typeof keyFn === 'function') return keyFn(props.modelOptions.root, props);
			return keyFn;
		})();

		const setError = (err) => {
			this.updateState({ error: err });
			console.log("Model " + modelName + " error: " + err);
			return Promise.resolve();
		}

		if (model.useKey && !key) return Promise.resolve(); //return setError('Model ' + modelName + ' key ['+keyField+'] not found');

		if (!modelName || !(modelName in props.modelOptions.stateModels)) return setError("Model schema not found");
		const models = props.modelOptions.stateModels[modelName];

		if (model.list) {
			let alreadyLoaded = false;
			const listID = (typeof model.list === 'string') ? model.list : 'default';
			if (models.lists[listID]?.loaded) {
				if (!model.loadChildren || (model.loadChildren && models.lists[listID].childrenLoaded)) {
					alreadyLoaded = true;
				}
			}

			if (forceLoad || model.forceLoad || !alreadyLoaded) {//} && !this.state.modelsLoading) {
				const apiMethod = 'list' + modelName + 's';
				if (!(apiMethod in api[modelName])) return setError('Model ' + modelName + ' api method "' + apiMethod + '" not found');

				const data = { data: {
					currentPage: this.state.pagination?.currentPage || 1
				} };
				if (model.loadChildren) data.loadChildren = model.loadChildren;
				if (model.useKey) data.data[keyField] = key;

				//this.updateState({modelsLoading: true});
/*
				api[modelName]['listCount'+modelName](data).then((response) => {
					this.updateState({pagination: {...this.state.pagination, listCount: response.data.listCount}});
				}).catch((error) => {
					console.error(modelName, apiMethod, error);
				});
*/
				return api[modelName][apiMethod](data).then((response) => {
					//console.log(modelName, apiMethod, response, model, props.modelOptions);
					//this.updateState({modelsLoading: false}); // todo: check all models loaded
					
				}).catch((error) => {
					console.error(modelName, apiMethod, error);
					//this.updateState({modelsLoading: false}); // todo: check all models loaded
				});
			}
		} else if (key && (model.forceLoad || (!(key in models)) || (model.loadChildren && !models[key]?.childrenLoaded))) {
			const apiMethod = 'get' + modelName;
			if (!(apiMethod in api[modelName])) return setError('Model ' + modelName + ' api method "' + apiMethod + '" not found');

			//this.updateState({modelsLoading: true});

			const data = { data: { [keyField]: key } };
			if (model.loadChildren) data.loadChildren = model.loadChildren;

			return api[modelName][apiMethod](data).then((response) => {
				//console.log(modelName, apiMethod, response);
				//this.updateState({modelsLoading: false}); // todo: check all models loaded
				return props.modelOptions.stateModels[modelName][key];
			}).catch((error) => {
				console.error(modelName, apiMethod, error);
				//this.updateState({modelsLoading: false}); // todo: check all models loaded
			});
		}
		return Promise.resolve().then(() => props.modelOptions.stateModels[modelName]?.[key]);
	}
	renderError(errText = null) {
		if (errText) return (<Text style={styles.error}>{errText || this.props.modelError}</Text>);
		if (this.props.modelError) return (<Text style={styles.error}>{errText || this.props.modelError}</Text>);
	}
	getModelPropValue(fieldName, modelName, modelID, valueModifier) {
		return this.getModelPropValues([fieldName], modelName, modelID, valueModifier)[fieldName];
	}
	getModelValue(...args) {
		return this.getModelPropValue(...args);
	}
	getModelPropValues(fieldNames = [], modelName, modelID, valueModifier) {
		const results = fieldNames.reduce((obj, name) => { obj[name] = null; return obj; }, {});

		if (modelName && !modelID) {
			modelID = this.props.id;
			if (!modelID) for (modelID in this.props.models[modelName]) break;
		}
		if (modelName in this.props.models && modelID in this.props.models[modelName]) {
			fieldNames.forEach(name => {
				const value = this.props.models[modelName][modelID].model.data?.[name]?.value || null;
				results[name] = (value && valueModifier) ? valueModifier(value) : value;
			});
		}
		return results;
	}
	getModelStateValue(fieldName, modelName, modelID, valueModifier) {
		return this.getModelStateValues([fieldName], modelName, modelID, valueModifier)[fieldName];
	}
	getModelStateValues(fieldNames = [], modelName, modelID, valueModifier) { //, labelFieldModifier = (name) => this.camelCaseToLabel(name)) => {
		const results = fieldNames.reduce((obj, name) => { obj[name] = null; return obj; }, {});
		const models = this.props.modelOptions.stateModels;
		if (!modelName in models) return results;

		const modelList = models[modelName];
		if (modelID in modelList) {
			const data = modelList[modelID].data;
			fieldNames.forEach(name => {
				if (name in data) results[name] = valueModifier ? valueModifier(data[name].value) : data[name].value;
			});
		}
		return results;
	}
	modelPropValueGetter(modelName, modelID, valueModifier = null) {
		return (fieldNames) => {
			if (Array.isArray(fieldNames)) {
				const results = {};
				for (const fieldName of fieldNames) results[fieldName] = this.getModelPropValue(fieldName, modelName, modelID, valueModifier);
				return results;
			}
			return this.getModelPropValue(fieldNames, modelName, modelID, valueModifier);
		};
	}
	modelValueGetter(modelName, modelID, valueModifier = null) {
		return this.modelPropValueGetter(modelName, modelID, valueModifier);
	}
	getModelValues(...args) {
		return this.getModelPropValues(...args);
	}
	getModelDataFromProps(modelName, modelID) {
		if (!this.props.models[modelName]?.[modelID]?.model?.data) return null;
		const modelData = this.props.models[modelName][modelID].model?.data;
		const data = {};
		for (const fieldName in modelData) {
			data[fieldName] = modelData[fieldName].value;
		}
		return {
			name: modelName,
			data: data,
			children: null
		}
	}
	getModelData(modelName, modelID) {
		return this.getModelDataFromProps(modelName, modelID);
	}
	makeSimpleList(modelName, callback, filter = () => true) {
		return this.getModelList(modelName, filter).map(callback);
	}
	modelListLoaded(modelName) {
		return ((modelName in this.props.models) && Object.keys(this.props.models[modelName]).length !== 0);
	}
	getFirstModel(modelName, filter = () => true) {
		if (!(modelName in this.props.models)) return null;
		for (const modelID in this.props.models[modelName]) {
			const model = this.props.models[modelName][modelID].model;
			if (filter(model)) return model.data;
		}
		return null;
	}
	getModelList(modelName, filter = () => true, withChildren = false) {
		const list = [];
		if (!(modelName in this.props.models)) return list;
		const models = Object.values(this.props.models[modelName]);
		if (models.length == 0) return list;
		models.forEach((model) => {
			if (model && filter(model)) {
				const data = model.model.data;
				if (withChildren && model.model.children) {
					const addChildren = (data, children) => {
						data.children = {};
						for (const childName in children) {
							if (!(childName in data.children)) data.children[childName] = {};
							for (const child of children[childName]) {
								const childData = this.props.models[child.model]?.[child.id]?.model?.data;
								if (childData) {
									const resultChild = {model: child.model, data: childData};
									data.children[childName][child.id] = resultChild

									const childChildren = this.props.models[child.model]?.[child.id]?.model?.children;
									if (childChildren) addChildren(resultChild.data, childChildren);
								}
							}
						}
						//console.log(data.children);
						return data;
					}
					addChildren(data, model.model.children);
				}
				list.push(data);
			}
		});
		return list;
	}
	makeGrid(modelName, callback, filter = () => true, gap = 20, emptyText) {
		if (!emptyText) emptyText = "No " + this.camelCaseToLabel(modelName) + "s Found";
		if (!(modelName in this.props.models)) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>;
		const models = Object.values(this.props.models[modelName]);
		if (models.length == 0) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>
		return (
			<View style={{ flexDirection: 'row', gap: gap, flexWrap: 'wrap' }}>
				{models.map((model, idx) => {
					if (!model || (filter && !filter(model))) return null;
					const data = model.model.data;
					return callback(data, idx);
				})}
			</View>
		);
	}
	makeSortedList(modelName, sorter = () => true, callback, filter = () => true, gap = 20, emptyText) {
		if (!emptyText) emptyText = "No " + this.camelCaseToLabel(modelName) + "s Found";
		if (!(modelName in this.props.models)) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>;
		const models = Object.values(this.props.models[modelName]);
		if (models.length == 0) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>
		models.sort(sorter);
		return (
			<View style={{ flexDirection: 'column', gap }}>
				{models.map((model, idx) => {
					if (!model || (filter && !filter(model))) return null;
					const data = model.model.data;
					return callback(data, idx);
				})}
			</View>
		);
	}
	makeList(modelName, callback, filter = () => true, gap = 20, emptyText) {
		if (!emptyText) emptyText = "No " + this.camelCaseToLabel(modelName) + "s Found";
		if (!(modelName in this.props.models)) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>;
		const models = Object.values(this.props.models[modelName]);
		if (models.length == 0) return <Text style={{ textAlign: 'center' }}>{emptyText}</Text>
		return (
			<View style={{ flexDirection: 'column', gap }}>
				{models.map((model) => {
					if (!model || (filter && !filter(model))) return null;
					const data = model.model.data;
					return callback(data);
				})}
			</View>
		);
	}
	makeDropdownOptions = (modelName, labelField = 'name', valueField = 'id', filter = () => true) => { //, labelFieldModifier = (name) => this.camelCaseToLabel(name)) => {
		const options = [];
		const models = this.props.modelOptions.stateModels;
		if (!modelName in models) return options;

		const modelList = models[modelName];
		for (const modelID in modelList) {
			if (modelID === 'default' || modelID === 'responseLength' || modelID === 'lists') continue;
			const model = modelList[modelID];
			if (!filter(model)) continue;
			const data = model.data;
			if (data[labelField].value && data[valueField].value) options.push({ label: data[labelField].value, value: data[valueField].value });
		}
		return options;
	}
	renderModelField({ name, model, id, label, valueModifier }) {
		if (!label) label = ucFirst(name);
		let property = ucFirst(this.getModelValue(name, model, id, valueModifier));
		return this.renderTextField(label, property);
	}
	render() {
		if (this.props.loadingName === 'Account') console.log(this.props);
		if (!this.props.modelLoaded) return this.loadingView();
		return super.render();
	}
}

export class ModelEditorComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = this.getResetState();
	}
	componentDidUpdate(prevProps, prevState) {
		const reloaded = super.componentDidUpdate(prevProps, prevState)
		/*
		if ((!prevProps.modelLoaded && this.props.modelLoaded) || (this.getKey() != this.state.modelID)) {
			this.resetModel();
		}
		*/
		if (!this.props.modelLoaded) {
			if (this.state.modelLoaded) this.updateState({ modelLoaded: false });
		} else if (!this.state.modelLoaded || (this.props.id != this.state.modelID)) {
			this.resetModel();
			return true;
		}
		for (const modelName in this.props.models) {
			const modelList = this.props.models[modelName];
			if (!(modelName in this.state.models)) {
				this.resetModel();
				return true;
			}
			const stateModelList = this.state.models[modelName];
			for (const modelID in modelList) {
				if (!(modelID in stateModelList)) {
					this.resetModel();
					return true;
				}
			}
		}
		return reloaded;
	}
	getResetState() {
		const newModels = {};
		for (const modelName in this.props.models) {
			const modelList = this.props.models[modelName];
			newModels[modelName] = {};
			for (const modelID in modelList) {
				newModels[modelName][modelID] = { ...cloneModel(modelList[modelID].model), modelLoaded: modelList[modelID].modelLoaded, uploads: {/* fieldName: upload, ... */} };
			}
		}
		return {
			...(this.extraState || {}),
			modelID: this.props.id,
			models: newModels,
			modelLoaded: true,
			error: null,
			modelChanges: {}, // [modelName][modelID][modelField] = newValue
		};
	}
	resetIndividualModels(models) { // {modelName: [modelID, ...], ...}
		for (const modelName in models)
			for (const modelID of models[modelName]) 
				this.resetIndividualModel(modelName, modelID);
	}
	resetIndividualModel(modelName, modelID, onDone = () => {}) {
		const newModels = this.state.models;
		const newModelChanges = this.state.modelChanges;

		const modelList = this.props.models[modelName];
		if (!(modelName in newModels)) newModels[modelName] = {};
		if (modelID === '*') for (modelID in modelList) {
			newModels[modelName][modelID] = { 
				...cloneModel(modelList[modelID].model), 
				modelLoaded: modelList[modelID].modelLoaded, 
				uploads: {/* fieldName: upload, ... */} 
			}
			if (newModelChanges?.[modelName]?.[modelID]) delete newModelChanges[modelName][modelID];
		}
		else if (modelID in modelList) {
			newModels[modelName][modelID] = { 
				...cloneModel(modelList[modelID].model), 
				modelLoaded: modelList[modelID].modelLoaded, 
				uploads: {/* fieldName: upload, ... */} 
			};
			if (newModelChanges?.[modelName]?.[modelID]) delete newModelChanges[modelName][modelID];
		}

		this.updateState({
			models: newModels,
			modelChanges: newModelChanges, // [modelName][modelID][modelField] = newValue
		}, onDone);
	}
	resetModel(onDone = () => {}) {
		this.setState(this.getResetState(), onDone);
	}
	resetDefaultModel(modelName) {
		return new Promise((resolve, reject) => {
			const models = this.props.modelOptions.stateModels[modelName];
			const newModel = { ...cloneModel(models.default) };

			const newModels = {};
			let found = false;
			for (const stateModelName in this.state.models) {
				const modelList = this.state.models[stateModelName];
				newModels[stateModelName] = {};
				for (const modelID in modelList) {
					if (modelID === 'default' && modelName === stateModelName) {
						newModels[stateModelName][modelID] = newModel;
						found = true;
					}
					else newModels[stateModelName][modelID] = { ...cloneModel(modelList[modelID]), modelLoaded: false, uploads: {/* fieldName: upload, ... */} };
				}
			}
			if (!found) newModels[modelName]['default'] = newModel;

			this.updateState({models: newModels}, resolve);
		});
	}
	getModelValue(fieldName, modelName, modelID, valueModifier) {
		return this.getModelValueFromState(this.state, fieldName, modelName, modelID, valueModifier);
	}
	getModelValues(fieldNames = [], modelName, modelID, valueModifier) {
		return this.getModelValuesFromState(this.state, fieldNames, modelName, modelID, valueModifier);
	}
	getModelValueFromState(state, fieldName, modelName, modelID, valueModifier) {
		if (!state || !fieldName) return null;
		return this.getModelValuesFromState(state, [fieldName], modelName, modelID, valueModifier)[fieldName];
	}
	getModelValuesFromState(state, fieldNames = [], modelName, modelID, valueModifier) {
		const results = fieldNames.reduce((obj, name) => { obj[name] = null; return obj; }, {});

		if (modelName && modelID && state.models && modelName in state.models && modelID in state.models[modelName]) {
			fieldNames.forEach(name => {
				const value = state.models[modelName][modelID].data[name]?.value || null;
				results[name] = (value && valueModifier) ? valueModifier(value) : value;
			});
		}
		return results;
	}
	getModelUploadValues(fieldNames = [], modelName, modelID) {
		const state = this.state;
		const results = fieldNames.reduce((obj, name) => { obj[name] = null; return obj; }, {});

		if (modelName && modelID && modelName in state.models && modelID in state.models[modelName]) {
			fieldNames.forEach(name => {
				results[name] = state.models[modelName][modelID].uploads?.[name]?.uri || this.getImageUri(state.models[modelName][modelID].data[name]?.value) || null;
			});
		}
		return results;
	}
	setModelFieldUpload(upload, fieldName, modelName, modelID) {
		return new Promise((resolve, reject) => {
			const newState = { models: {}, modelChanges: {} };
			for (const stateModelName in this.state.models) {
				const modelList = this.state.models[stateModelName];
				newState.models[stateModelName] = {};
				newState.modelChanges[stateModelName] = {};
				for (const stateModelID in modelList) {
					newState.models[stateModelName][stateModelID] = { ...cloneModel(modelList[stateModelID]), uploads: {...modelList[stateModelID].uploads} };
					newState.modelChanges[stateModelName][stateModelID] = {};
					if (stateModelName in this.state.modelChanges && stateModelID in this.state.modelChanges[stateModelName]) {
						newState.modelChanges[stateModelName][stateModelID] = {...this.state.modelChanges[stateModelName][stateModelID]}
					} else {
						newState.modelChanges[stateModelName][stateModelID] = {}
					}
				}
			}

			//console.log(upload, fieldName, modelName, modelID);

			if (modelName) {
				if (!modelID) modelID = 'default';
				if (modelName in newState.models && modelID in newState.models[modelName]) {
					//if (!(fieldName in newState.model.data)) console.warn(fieldName + ' not in model'); // check schema instead

					if (upload) {
						newState.models[modelName][modelID].uploads[fieldName] = upload;
						newState.modelChanges[modelName][modelID]['__upload_'+fieldName] = upload;
					} else {
						if (fieldName in newState.models[modelName][modelID].uploads) {
							delete newState.models[modelName][modelID].uploads[fieldName];
							delete newState.modelChanges[modelName][modelID]['__upload_'+fieldName];
						}
						newState.models[modelName][modelID].data[fieldName].value = null;
						newState.modelChanges[modelName][modelID][fieldName] = null;
					}
				}
			}
			//console.log(newState);
			this.updateState(newState, resolve);
		});
	}
	setModelFields(fieldKeyValuePairs, modelName, modelID) {
		return new Promise((resolve, reject) => {
			const newState = { models: {}, modelChanges: {} };
			for (const stateModelName in this.state.models) {
				const modelList = this.state.models[stateModelName];
				newState.models[stateModelName] = {};
				newState.modelChanges[stateModelName] = {};
				for (const stateModelID in modelList) {
					newState.models[stateModelName][stateModelID] = { ...cloneModel(modelList[stateModelID]), uploads: {...modelList[stateModelID].uploads} };
					newState.modelChanges[stateModelName][stateModelID] = {};
					if (stateModelName in this.state.modelChanges && stateModelID in this.state.modelChanges[stateModelName]) {
						newState.modelChanges[stateModelName][stateModelID] = {...this.state.modelChanges[stateModelName][stateModelID]}
					} else {
						newState.modelChanges[stateModelName][stateModelID] = {}
					}
				}
			}

			if (modelName) {
				if (!modelID) modelID = 'default';
				if (modelName in newState.models && modelID in newState.models[modelName]) for (const fieldName in fieldKeyValuePairs) {
					if (!(fieldName in newState.models[modelName][modelID].data)) {
						console.log(fieldName + ' not in model'); 
						continue;					
					}
					const newValue = fieldKeyValuePairs[fieldName];

					newState.models[modelName][modelID].data[fieldName].value = newValue;
					newState.modelChanges[modelName][modelID][fieldName] = newValue;
				}
			}
			this.updateState(newState, resolve);
		});
	}
	modelHasChanged(name, id) {
		if (!name) name = this.props.modelName;
		if (!(name in this.state.modelChanges)) return false;
		if (!id) id = this.props.id;
		if (!(id in this.state.modelChanges[name])) return false;		

		for (const field in this.state.modelChanges[name][id]) return true;
		return false;
	}
	setModelField(fieldName, value, modelName, modelID) {
		return this.setModelFields({ [fieldName]: value }, modelName, modelID);
	}
	getModelData(modelName, modelID) {
		if (!this.state.models[modelName]?.[modelID]?.data) return null;
		const modelData = this.state.models[modelName][modelID].data;
		const data = {};
		// todo: only model changes?
		for (const fieldName in modelData) {
			data[fieldName] = modelData[fieldName].value;
		}
		return {
			name: modelName,
			data: data,
			uploads: this.state.models[modelName][modelID].uploads,
			children: null
		}
	}
	saveModel(create = false, children, _modelName, _modelID, ignoreFields = [], doUploads = true, uploadID = null, onlyFields = null, reloadModels = true, parent = null, parents = null) { //todo: make saving work with children
		if (typeof create !== "boolean") {
			const opt = create;
			create = opt.create != null ? opt.create : false;
			children = opt.children || children;
			_modelName = opt.model || opt.modelName || _modelName;
			_modelID = opt.id || opt.modelID || _modelID;
			ignoreFields = opt.ignoreFields || ignoreFields;
			onlyFields = opt.fields || opt.onlyFields || onlyFields;
			doUploads = opt.doUploads != null ? opt.doUploads : doUploads;
			uploadID = opt.uploadID || uploadID;
			reloadModels = opt.reloadModels != null ? opt.reloadModels : reloadModels;
			parent = opt.parent || parent; // { model, id } || [{ model, id }, ...]
			parents = opt.parents || parents;
		}
		const modelName = _modelName || this.props.modelName;
		const modelID = _modelID || (create ? 'default' : (_modelID));
		if (!modelName) return Promise.reject(new Error("Model name not defined"));

		if (!create && !this.props.modelLoaded) return Promise.reject(new Error("Model " + modelName + "not loaded"));
		const setError = (err) => {
			console.trace(err);
			this.updateState({ error: err });
			return Promise.reject(new Error("Model " + modelName + " error: " + err));
		}

		if (!parents) {
			//create parent data
			//parent = [];
			parents = [];

		}

		const modelData = {
			data: {},
			children: [],
			parent,
			parents,
		};

		let stateData = {};
		const uploads = {[modelName]: {}}; // {modelName: {id: uploads{fieldName: upload}, ...}, ...}
		if (modelName && (uploadID || modelID || create)) {
			stateData = this.state.models[modelName][modelID]?.data || {};
			if (doUploads) uploads[modelName][uploadID || modelID] = this.state.models[modelName][modelID]?.uploads || {};
		}

		for (const fieldName in stateData) {
			if (onlyFields && (!onlyFields.includes(fieldName) && fieldName !== 'id')) continue;
			if (ignoreFields.includes(fieldName)) continue;
			if (uploadID && fieldName === 'id') modelData.data[fieldName] = uploadID;
			else {
				const field = stateData[fieldName];
				modelData.data[fieldName] = field.value;
			}
		}
		//add children uploads to uploads too
		if (children) {
			for (const child of children) {
				if (!(child.name in uploads)) uploads[child.name] = {};
				if (doUploads) uploads[child.name][child.data.id] = child.uploads;
			}

			function setupChildren(modelArray, children) {
				for (const child of children) {
					const childObj = {name: child.name, data: child.data, children: []};
					if (child.children) setupChildren(childObj.children, child.children);
					modelArray.push(childObj);
				}
			}
			setupChildren(modelData.children, children);
		}

		//console.log(children, uploads, modelName, modelID, stateData);

		const apiMethod = (create ? 'add' : 'update') + modelName;

		if (!(apiMethod in api[modelName])) return setError('Model ' + modelName + ' api method "' + apiMethod + '" not found');
		//console.log(modelName, apiMethod, modelData);

		return new Promise((resolve, reject) => {
			this.updateState({awaitingRequest: true}, () => {
				api[modelName][apiMethod](modelData).then((response) => {
					//upload the files
					const uploadFile = ({uri, modelName, modelID, fieldName}) => {
						const fileExtension = (uri => {
							let fileExtension = 'jpg';
							if (uri.substr(0, 11) === 'data:image/' || uri.substr(0, 11) === 'data:video/') fileExtension = uri.substr(11, uri.indexOf(';')-11);
							else if (uri.lastIndexOf('.') != -1) fileExtension = uri.substr(uri.lastIndexOf('.') + 1);
							if (fileExtension === 'jpeg') fileExtension = 'jpg';
							return fileExtension;
						})(uri);

						const mimeType = (fileExtension === 'jpg') ? 'image/jpeg' : (uri.substr(0, 11) === 'data:video/' ? 'video/' : 'image/')+fileExtension;
					
						return fetch(uri)
						.then(response => response.blob())
						.then(blob => {
						const uploadData = new FormData();
					
						if (Platform.OS === 'web') {
							uploadData.append("file", blob, 'file.'+fileExtension);
						} else {
							uploadData.append("image", {
							name: 'file.'+fileExtension,
							type: mimeType,
							uri: uri,
							});
						}
						
						const user = this.props.modelOptions.root.userData;
						const body = { 
							userID: user.userID,
							organisationID: user.organisationID,
							siteID: user.siteID,
							connectionID: getConnectionID(), 
							modelName, 
							modelID, 
							modelField: fieldName
						}
						for (let key in body) {
							uploadData.append(key, body[key]);
						}
					
						return fetch(SERVER_DOMAIN+"/uploadFile", {
							method: "POST",
							body: uploadData,
						})
						}).then(response => {
						return response.text();
						}).then(responseText => {
						if (responseText.substring(0, 2) !== 'ok') return Promise.reject(new Error("An error occured while uploading the file. Please try again (images must be smaller than 100MB) or contact support."));
						return Promise.resolve({fileName: responseText.substring(3), modelName, modelID, fieldName });
						})
					}

					const uploadPromises = [];
					for (const modelName in uploads) {
						for (const modelID in uploads[modelName]) {
							let uploadModelID = modelID;
							if (modelID == 'default') {
								uploadModelID = response.data.data.data.id.value
							}
							for (const fieldName in uploads[modelName][modelID]) {
								const upload = uploads[modelName][modelID][fieldName];
								uploadPromises.push(uploadFile({uri: upload.uri, modelName, modelID: uploadModelID, fieldName}).then(result => {
									//this.setModelField(fieldName, result.fileName, modelName, modelID);
									//update root store
									rootStore.dispatch({ 
										type: 'UPDATE_MODEL', 
										payload: {
											name: modelName,
											data: {
												data: {
													id: result.modelID, 
													[fieldName]: result.fileName
												}
											},
										}
									});				
								}));
							}
						}
					}

					return Promise.all(uploadPromises).then(() => {
						if (doUploads && reloadModels) this.updateState({ modelLoaded: false, awaitingRequest: false });
						return response;
					});
				}).then(() => {
					return resolve();
				}).catch((err) => {
					this.updateState({awaitingRequest: false});
					setError(err).then(reject);
				});
			});
		});
	}
	renderError(errText = null) {
		if (errText) return super.renderError(errText);
		const result = super.renderError();
		if (!result && this.state.error) return (<Text style={styles.error}>{(typeof this.state.error === 'object' && 'toString' in this.state.error) ? this.state.error.toString() : this.state.error}</Text>);
		return result;
	}
	getFormHelper(model, id) {
		const makeCall = (fn) => (...args) => {
			const opt = args[0] || {};
			opt.model = model;
			opt.id = id;
			args[0] = opt;

			return fn(...args);
		}
		const makeFieldCall = (fn) => (fieldName, ...args) => {
			const opt = typeof fieldName === 'object'? fieldName : (typeof args[0] === 'object'? args[0] : {});
			opt.model = model;
			opt.id = id;
			if (typeof fieldName === 'object') {
				args.unshift(opt)
			} else {
				opt.name = fieldName;
				if (typeof args[0] === 'object') args[0] = opt;
				else args.unshift(opt);
			}
			return fn(...args);
		}
		return {
			id,
			modelName: model,
			//values
			save: makeCall((...args) => this.saveModel(...args)),
			create: makeCall((...args) => { typeof args[0] === 'object'? args[0].create = true : args[0] = true; return this.saveModel(...args); }),
			hasChanged: () => this.modelHasChanged(model, id),
			modelHasChanged: () => this.modelHasChanged(model, id),
			value: (fieldName, valueModifier = null) => this.getModelValue(fieldName, model, id, valueModifier),
			getModelValue: (fieldName, valueModifier= null) => this.getModelValue(fieldName, model, id, valueModifier),
			values: (fieldNames = [], valueModifier = null) => this.getModelValues(fieldNames, model, id, valueModifier),
			getModelValues: (fieldNames = [], valueModifier = null) => this.getModelValues(fieldNames, model, id, valueModifier),
			setValue: (fieldName, value) => this.setModelField(fieldName, value, model, id),
			setModelField: (fieldName, value) => this.setModelField(fieldName, value, model, id),
			setValues: (fieldKeyValuePairs) => this.setModelFields(fieldKeyValuePairs, model, id),
			setModelFields: (fieldKeyValuePairs) => this.setModelFields(fieldKeyValuePairs, model, id),
			setUpload: (fieldName, upload) => this.setModelFieldUpload(upload, fieldName, model, id),
			getUpload: (fieldNames = []) => this.getModelUploadValues(fieldNames, model, id),
			// render functions
			textField: makeFieldCall((...args) => this.renderModelField(...args)),
			textInput: makeFieldCall((...args) => this.renderModelTextInput(...args)),
			slider: makeFieldCall((...args) => this.renderModelSlider(...args)),
			radioButtons: makeFieldCall((...args) => this.renderModelRadioButtons(...args)),
			checkBox: makeFieldCall((...args) => this.renderModelCheckBox(...args)),
			colorPicker: makeFieldCall((...args) => this.renderModelColorPicker(...args)),
			filePicker: makeFieldCall((...args) => this.renderModelFilePicker(...args)),
			dropdown: makeFieldCall((...args) => this.renderModelDropdown(...args)),
		}
	}
	renderModelTextInput(opt, multiline = false) {
		const fieldName = typeof opt === 'string' ? opt : opt.name;
		let displayText = typeof opt === 'string' ? null : opt.label;
		if (typeof opt !== 'string' && opt.multiline) multiline = opt.multiline;
		if (!displayText) {
			displayText = this.camelCaseToLabel(fieldName);
		}
		const model = (typeof opt !== 'string' ? opt.model : null);
		const id = (typeof opt !== 'string' ? opt.id : null);

		return this.renderTextInput({
			name: displayText,
			value: this.getModelValue(fieldName, model, id),
			onUpdate: (val) => { this.setModelField(fieldName, val, model, id).then(()=> {if (opt.onChange) opt.onChange(val); }); },
			numeric: opt.numeric || false,
			placeholder: opt.placeholder,
			multiline
		});
	}
	renderModelSlider(opt) {
		opt.value = this.getModelValue(opt.name, opt.model, opt.id);
		const onChange = opt.onChange;
		opt.onChange = (value) => {
			this.setModelField(opt.name, value, opt.model, opt.id).then(()=> {
				if (onChange) onChange(value);
			});
		}
		return this.renderSlider(opt);
	}
	renderModelRadioButtons(opt) {
		opt.value = this.getModelValue(opt.name, opt.model, opt.id);
		const onChange = opt.onChange;
		opt.onChange = (value) => {
			this.setModelField(opt.name, value, opt.model, opt.id).then(()=> {
				if (onChange) onChange(value);
			});
		}
		return this.renderRadioButtons(opt)
	}
	renderModelCheckBox(opt) {
		const fieldName = typeof opt === 'string' ? opt : opt.name;
		if (typeof opt === 'string') opt = {};

		opt.value = this.getModelValue(fieldName, opt.model, opt.id);
		const onChange = opt.onChange;
		opt.onChange = (value) => {
			this.setModelField(fieldName, value, opt.model, opt.id).then(()=> {
				if (onChange) onChange(value);
			});
		}
		if (!opt.label) opt.label = this.camelCaseToLabel(fieldName);
		return this.renderCheckBox(opt)
	}
	renderModelColorPicker(opt) {
		const value = this.getModelValue(opt.name, opt.model, opt.id);
		const onChange = opt.onChange;
		if (!opt.label) opt.label = this.camelCaseToLabel(opt.name);
		return this.renderColorPicker(opt.label, value, (newValue) => {
			this.setModelField(opt.name, newValue, opt.model, opt.id).then(()=> {
				if (onChange) onChange(newValue);
			});
		}, opt.palette, opt.onPaletteAdd);
	}
	renderModelFilePicker(opt) {
		const value = this.getModelValue(opt.name, opt.model, opt.id);
		const onChange = opt.onChange;
		if (!opt.label) opt.label = this.camelCaseToLabel(opt.name);
		return this.renderFilePicker(opt.label, this.getImageUri(value), (file) => {
			this.setModelFieldUpload(file, opt.name, opt.model, opt.id).then(()=> {
				if (onChange) onChange(file);
			});
		}, opt.extensions);
	}
	modelValueGetter(modelName, modelID, valueModifier = null) {
		return (fieldNames) => {
			if (Array.isArray(fieldNames)) {
				const results = {};
				for (const fieldName of fieldNames) results[fieldName] = this.getModelValue(fieldName, modelName, modelID, valueModifier);
				return results;
			}
			return this.getModelValue(fieldNames, modelName, modelID, valueModifier)
		};
	}
	renderModelDropdown = ({ name, fieldName, model, id, displayText, label, options, onChange, numericInt = false, numericFloat = false }) => {
		if (label) displayText = label;
		if (!fieldName) fieldName = name;
		if (!displayText) displayText = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
		const value = this.getModelValue(fieldName, model, id);
		return this.renderDropdown({
			name: displayText,
			defaultValue: value || '',
			options: options,
			numericInt,
			numericFloat,
			onChange: (itemValue, itemIndex) => {
				this.setModelField(fieldName, itemValue, model, id).then(() => {
					if (onChange) onChange(itemValue);
				});
			}
		});
	}
}

import Modal from '../components/mobileUI/modal.component';
export class SimpleModal extends APIComponent {
	constructor(props) {
		super(props);
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		//auto clear the state when modal closes, unless rememberState={true} is set
		if (!this.props.rememberState && prevProps.open === true && this.props.open === false) {
			this.setState({
				error: null,
			});
		}
	}
	renderContent() {
		return (
			<Modal open={this.props.open} showButtons={false} onClose={this.props.closeModal} style={{minHeight: 400, flexShrink: 1}}>
				<View style={{ flex: 1, flexDirection: 'column', minWidth: 450 }}>
					<Text style={styles.title}>{this.props.title}</Text>
					<ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1, padding: 20}} scrollIndicatorInsets={{ right: 1 }}>
						{this.props.children}
					</ScrollView>
					<View>{this.renderError()}</View>
					<View style={{ marginTop: 20 }}>
						<View style={{ flexDirection: 'row' }}>
							<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
								<Button title="Done" style={{ width: 100 }} onPress={() => this.props.closeModal()} />
							</View>
						</View>	
					</View>
				</View>
			</Modal>
		);
	}
	render() {
		if (this.state.internalError) return this.renderError();
		return (<>
			{this.renderConfirmModal()}
			{(this.renderContent) ? this.renderContent() : null}
		</>);
	}
}
export class APIModal extends APIComponent {
	constructor(props, modalTitle = '') {
		super(props);
		this.modalTitle = modalTitle;
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		//auto clear the state when modal closes, unless rememberState={true} is set
		if (!this.props.rememberState && prevProps.open === true && this.props.open === false) {
			this.setState({
				error: null,
			});
		}
	}
	renderContent() {
		return (
			<Modal open={this.props.open} showButtons={false} onClose={this.props.closeModal} style={{minHeight: 400, flexShrink: 1}}>
				<View style={{ flex: 1, flexDirection: 'column', minWidth: 450 }}>
					<Text style={styles.title}>{this.modalTitle}</Text>
					<ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1, padding: 20}} scrollIndicatorInsets={{ right: 1 }}>
						{this.renderModal
							? <View style={{ flex: 1, flexDirection: 'column' }}>
								{this.renderModal()}
							  </View>
							: <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', gap: 40 }}>
								<View style={{ flex: 1, minWidth: 450 }}>{this.renderModalLeft()}</View>
								<View style={{ flex: 1, minWidth: 450 }}>{this.renderModalRight()}</View>
							</View>
						}
					</ScrollView>
					<View>{this.renderError()}</View>
					{this.renderModalBottom && <View style={{ marginTop: 20 }}>{this.renderModalBottom()}</View>}
				</View>
			</Modal>
		);
	}
	render() {
		if (this.state.internalError) return this.renderError();
		return (<>
			{this.renderConfirmModal()}
			{(this.renderContent) ? this.renderContent() : null}
		</>);
	}
}
export class ModelModal extends ModelEditorComponent {
	constructor(props, modalTitle = '') {
		super(props);
		this.modalTitle = modalTitle;
	}
	componentDidUpdate(prevProps, prevState) {
		const reloaded = super.componentDidUpdate(prevProps, prevState);
		//auto clear the state when modal closes, unless rememberState={true} is set
		if (!this.props.rememberState && prevProps.open === true && this.props.open === false) {
			this.setState({
				model: this.props.model,
				modelLoaded: this.props.modelLoaded,
				error: null,
			});
		}
		return reloaded;
	}
	renderContent() {
		if (!this.props.open) return null;
		return (
			<Modal open={this.props.open} showButtons={false} onClose={this.props.closeModal} style={{minHeight: 500, flexShrink: 1}}>
				<View style={{ flex: 1, flexDirection: 'column', minWidth: 530 }}>
					<Text style={styles.title}>{this.modalTitle}</Text>
					<ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1, padding: 20}} scrollIndicatorInsets={{ right: 1 }}>
						{this.renderModal
							? <View style={{ flex: 1, flexDirection: 'column' }}>
								{this.renderModal()}
							  </View>
							: <View style={{ flex: 1, flexDirection: 'row', gap: 40, flexWrap: 'wrap' }}>
								<View style={{ flex: 1, minWidth: 530 }}>{this.renderModalLeft()}</View>
								<View style={{ flex: 1, minWidth: 530 }}>{this.renderModalRight()}</View>
							</View>
						}
					</ScrollView>
					<View>{this.renderError()}</View>
					{this.renderModalBottom && <View style={{ marginTop: 10, borderTopWidth: 1, borderColor: '#DDD', paddingTop: 10 }}>{this.renderModalBottom()}</View>}
				</View>
			</Modal>
		);
	}
	render() {
		if (this.state.internalError) return this.renderError();
		return (<>
			{this.renderConfirmModal()}
			{(this.renderContent) ? this.renderContent() : null}
		</>);
	}
}


export function connect({model, models = (model?[model]:[]), component, loadingName, loadingSize = null}) { //loadingName = (_camelCaseToLabel(model)+(list?'s':''))
	if (!component) throw new Error("Component not set in connect function (with options: models = "+JSON.stringify(models)+")");
	if (!Array.isArray(models)) models = [models];

	return reduxConnect((state, componentProps) => {
		const root = state.root;
		const stateModels = state.models;
		
		const props = {
			loadingName: loadingName,
			loadingSize: loadingSize,
			models: {}, // read only // { modelName: { modelID: {fields...}}, ... }
			modelLoaded: false,
			modelError: null,
			modelOptions: { models, component, loadingName, loadingSize, stateModels, root }
		}

		function setError(err) {
			props.modelError = err;
			props.modelLoaded = false;
			return props;
		}

		/**
		 * Load models into props
		 */

		for (let i = 0; i < models.length; i++) {
			const model = models[i];

			let result = true;
			
			switch (typeof model) {
				case 'function':
					result = connectModel(model(state.root, componentProps));
					break;
				case 'object':
					result = connectModel(model);
					break;
				case 'string':
					result = connectModel({name: model});
					break;
				default:
					if (model) return setError("Unknown model type: "+JSON.stringify(model));
			}

			if (!result) return props; // error is set in connectModel function
		}
		props.modelLoaded = true;

		function connectModel({model = null, name = model, key, keyField = 'id', list = false, useKey, childrenOnly = false, loadChildren = (childrenOnly || false), require = true}) {
			if (!key) key = componentProps[keyField];
			else if (typeof key === 'function') key = key(state.root, componentProps);
			if (!key && !list) key = 'default'; //return !require; // false

			function setError(err) {
				props.modelError = err;
				props.modelLoaded = false;
				return false;
			}

			const modelProps = {
				modelName: name,
				model: {},
				modelLoaded: false,
				modelError: null,
			}
	
			if (!name || !(name in stateModels)) return setError("Model schema not found for "+name);
			const stateModelList = stateModels[name];

			if (!(name in props.models)) props.models[name] = {};

			function getIDsFromKeyField(keyField, key) {
				const IDs = [];
				for (const id in stateModelList) {
					if (id === 'default' || id === 'responseLength' || id === 'lists') continue;
					if (!useKey || stateModelList[id].data?.[keyField]?.value === key) IDs.push(id);
				}
				return IDs;
			}

			function setupChild(child) {
				const childModelName = child.model;
				const childID = child.id;

				if (!childModelName in stateModels) return false; //not loaded
				const childModelsList = stateModels[childModelName];
				if (childModelsList.responseLength === null) return false; //not loaded

				if (!(childID in childModelsList)) return false; //not loaded

				const childModelProps = {
					modelName: childModelName || null,
					model: {},
					modelLoaded: false,
					modelError: null,
				}

				childModelProps.modelLoaded = true;
				childModelProps.model = cloneModel(childModelsList[childID]);
				if (childModelProps.model.children) setupChildren(childModelProps.model.children);

				if (!(childModelName in props.models)) props.models[childModelName] = {};
				props.models[childModelName][childID] = childModelProps;

				return true;
			}
			function setupChildren(children) {
				let allLoaded = true;
				for (const childName in children) {
					const childrenList = children[childName];
					for (const child of childrenList) {
						if (!setupChild(child)) allLoaded = false;
					}
				}
				return allLoaded;
			}

			if (!list) {
				/**
				 * if get
				 */

				let modelID = key;
				if (useKey && keyField) {
					const IDs = getIDsFromKeyField(keyField, key);
					if (IDs.length === 0) return !require;
					modelID = IDs[0];
				}
				
				if (!(modelID in stateModelList)) return !require;
					
				modelProps.model = cloneModel(stateModelList[modelID]);
				modelProps.modelLoaded = true;
				
				if (!childrenOnly) props.models[name][modelID] = modelProps;
				if (loadChildren) {
					//add children to models prop
					if (!setupChildren(modelProps.model.children)) return !require;
				}
			} else {
				/**
				 * if list
				 */
				if (stateModelList.responseLength === null) return false;

				let allChildrenLoaded = true;
				getIDsFromKeyField(keyField, key).forEach(modelID => {
					const newModel = stateModelList[modelID];
					if (!childrenOnly) props.models[name][modelID] = { ...modelProps, model: newModel };
					//add children to models prop
					if (loadChildren && !setupChildren(newModel.children)) allChildrenLoaded = false;
				});

				if (!allChildrenLoaded) return false;
			}

			return true;
		}

		return props;
	})(component);
}
