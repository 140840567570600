import React, {useState} from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform, TextInput as RNTextInput } from 'react-native';
import { TextInput as PaperTextInput } from 'react-native-paper';
import { styles } from '../main.styles';

export default class TextInput extends React.Component {
	render() {
		const props = this.props;
		if (Platform.OS == 'web') return(
			<View style={props.style}>
			{props.showLabel !== false && <Text style={styles.label}>{props.label}:</Text>}
			<RNTextInput
				keyboardType={props.numeric ? "numeric": "default"}
				style={[styles.input, props.inputStyle||{}, {caretColor: 'rgba(0,0,0,0.5)'}]}
				placeholder={props.placeholder || props.label}
				onChangeText={(value) => {
					if (props.numeric) {
						const numericValue = value.replace(/[^0-9]/g, '');
						props.onChangeText(numericValue.indexOf('.') === -1 ? parseInt(numericValue) : parseFloat(numericValue));
					} 
					else props.onChangeText(value)
				}}
				value={props.value || ''}
				multiline={props.multiline}
				underlineColorAndroid="transparent"
				placeholderTextColor="grey"
				autoCapitalize={props.autoCapitalize || "none"}
				autoFocus={props.initialFocus || false}
				textContentType={props.textContentType}
				secureTextEntry={props.secureTextEntry}
			/>
			</View>
		);
		const [value, setValue] = useState(props.value);
		return (
			<View style={[props.style, {width:'100%', caretColor: 'auto'}]}>
			<PaperTextInput 
				mode="outlined" 
				placeholder={props.placeholder}
				label={props.label}
				value={value}
				onChangeText={(value) => {
				setValue(value);
				props.onChangeText(value);
				}}
			/>
			</View>
		)
	}
}

